.splide:not(.splide--custom) {
    &.is-initialized {
        .splide {
            &__navigation {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;

                &:not(.splide__navigation--has-link) {
                    &::after {
                        content: "";
                        flex: 1;
                    }
                }
            }
        }
    }

    &.splide--hide-navigation {
        .splide {
            &__arrows,
            &__navigation:not(.splide__navigation--has-link),
            &__pagination {
                display: none !important;
            }
        }
    }

    .splide {
        &__track {

        }

        &__list {

        }

        &__slide {

        }

        &__footer {
            position: relative;
            margin-top: 32px;

            .splide {
                &__navigation {
                    margin-top: 0;

                    //.splide.is-initialized & {
                    //    position: static;
                    //}
                }
            }
        }

        &__navigation {
            display: none;
            margin-top: 32px;

            //.splide.is-initialized & {
            //    position: relative;
            //    display: flex;
            //    flex-direction: row;
            //    flex-wrap: wrap;
            //    //justify-content: center;
            //    align-items: center;
            //
            //    //@include media-breakpoint-up(md) {
            //    //    justify-content: flex-start;
            //    //}
            //
            //    &::after {
            //        content: "";
            //        flex: 1;
            //    }
            //}

            &.splide__navigation--has-link {
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
            }
        }

        &__arrows {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;

            button {
                &:not(:last-child) {
                    @include media-breakpoint-up(md) {
                        margin-right: 4px;
                    }
                }

                &.splide__toggle {
                    //@include media-breakpoint-down(md) {
                    //    position: absolute;
                    //    top: 0; left: 0;
                    //}

                    @include media-breakpoint-up(md) {
                        margin-right: 32px;
                    }
                }
            }
        }

        &__arrow {
            &--prev,
            &--next {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &--prev {
                img {
                    transform: rotate(-180deg);
                }
            }

            &[disabled] {
                cursor: not-allowed;
                opacity: .3;
                pointer-events: none;
                transition: opacity .2s linear;
            }

            span {
                &[aria-hidden='true'] {
                    font-size: 0;
                    line-height: 0;
                }
            }
        }

        &__pagination {
            counter-reset: tns-nav;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;

            @include media-breakpoint-up(md) {
                @if $button__type == edged {
                    gap: 0 8px;
                }
            }

            button {
                appearance: none;
                position: relative;
                margin: 0;
                padding: 0;
                width: 26px;
                height: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: $color__primary;
                background-color: transparent;
                border: 0;
                border-radius: $border__radius;
                pointer-events: none;
                @if $button__type == edged {
                    transition: width .3s ease-in-out, color .2s linear;
                } @else {
                    transition: color .2s linear;
                }

                @include media-breakpoint-up(md) {
                    width: 32px;
                    height: 32px;
                    pointer-events: auto;
                }

                @include hover-focus-visible {
                    @if $button__type == edged {
                        color: $color__primary--hover;
                    }

                    &::before {
                        @include media-breakpoint-up(md) {
                            @if $button__type == rounded {
                                background-color: currentColor;
                            }
                        }
                    }
                }

                &.is-active {
                    color: $color__primary--hover;

                    @include media-breakpoint-up(md) {
                        @if $button__type == edged {
                            width: 64px;
                        }
                    }

                    &::before {
                        background-color: currentColor;
                        border-color: currentColor;
                    }
                }

                &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border: 2px solid currentColor;
                    border-radius: 50%;
                    transition: background-color .2s linear;

                    @include media-breakpoint-up(md) {
                        @if $button__type == edged {
                            width: 100%;
                            height: 4px;
                            background-color: currentColor;
                            border: 0;
                            border-radius: 0;
                        } @else {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }

        &__counter {
            display: none;
            //display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 16px;

            @include media-breakpoint-up(md) {
                display: none;
            }

            span {
                &:first-child {
                    &::after {
                        // content: "\00a0\007C\00a0"; // " | "
                        // content: "\00a0\002F\00a0"; // " / "
                        // content: "\00a0\2044\00a0"; // " ⁄ "
                        content: "\002F";
                    }
                }
            }
        }

        &__link {
            display: flex;
            justify-content: flex-end;
            flex: 1;

            @include media-breakpoint-down(sm) {
                margin-top: 32px;
            }
        }
    }
}
